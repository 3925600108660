import React from "react";
import Navbar from "./navbar/Navbar";
// import Carousel from "react-bootstrap/Carousel";
import { Carousel } from "react-bootstrap";
import Footer from "./pages/footer";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import * as mdb from "mdb-ui-kit"; // lib
import { Input } from "mdb-ui-kit"; // module
import { Url } from "url";
// import "~mdb-ui-kit/css/mdb.min.css";
function Home() {
  return (
    <div>
      <Navbar />
      <div className="container-fluvid">
        <div className="row">
          <Carousel>
            <Carousel.Item>
              <div className="carousel-image-wrapper">
                <img
                  className="carousel-image"
                  src="https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/home%20page%2Fstandard-quality-control-concept-m.jpg?alt=media&token=b45585c7-1822-4289-bcc3-b5690c6152a3"
                  alt="First slide"
                />
                <div className="carousel-caption">
                  <h3>First slide label</h3>
                  <p>Text sentence on the right side</p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-image-wrapper">
                <img
                  className="carousel-image"
                  src="https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/home%20page%2Fstandard-quality-control-concept-m.jpg?alt=media&token=b45585c7-1822-4289-bcc3-b5690c6152a3"
                  alt="Second slide"
                />
                <div className="carousel-caption">
                  <h3>Second slide label</h3>
                  <p>Another text sentence on the right side</p>
                </div>
              </div>
            </Carousel.Item>
            {/* Add more Carousel.Item for additional slides */}
          </Carousel>
        </div>
      </div>
      <div class="container" style={{ marginTop: "4%" }}>
        <div class="row">
          <div class="col-md-4 mt-4">
            <div class="card" style={{ height: "300px" }}>
              <img
                style={{ marginRight: "40%", marginLeft: "40%" }}
                height={"100px"}
                width={"100px"}
                src="https://static.thenounproject.com/png/1587734-200.png"
              />
              <h4 className="mt-4 text-center">
                <strong>Quality</strong>
              </h4>
              <p className="mt-4 text-center">
                Our product range, which is of unrivaled quality, accords with
                customer demand and standards.
              </p>
            </div>
          </div>

          <div class="col-md-4 mt-4">
            <div class="card" style={{ height: "300px" }}>
              <img
                style={{ marginRight: "40%", marginLeft: "40%" }}
                height={"100px"}
                width={"100px"}
                src="https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/home%20page%2F152318978-why-us-icon.webp?alt=media&token=017a6282-cd32-47bd-bbd5-4c06dce56fb1"
              />
              <h4 className="mt-4 text-center">
                <strong>Why us</strong>
              </h4>
              <p className="mt-4 text-center">
                Our devotion to quality, high-tech standards, and reliable
                service have made us a pioneer in the export and import
                industry.
              </p>
            </div>
          </div>

          <div class="col-md-4 mt-4">
            <div class="card" style={{ height: "300px" }}>
              <img
                style={{ marginRight: "40%", marginLeft: "40%" }}
                height={"100px"}
                width={"100px"}
                src="https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/home%20page%2Fworld-import-export-line-icon-vector-illustration-sign-isolated-contour-symbol-black-206733793.jpg?alt=media&token=3bedbbe5-c91e-4e6c-88d7-936e01dfd660"
              />
              <h4 className="mt-4 text-center">
                <strong>Export Values</strong>
              </h4>
              <p className="mt-4 text-center">
                We at GEO Global Trades, exporters of both vegetables and spices
                from India, feel that providing value is always at the core of
                our company.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <div>
              <p className="text-justify">
                <h3 style={{ color: "#69AB1A" }}>
                  <strong>
                    Geo Global Trade is a leading export company.{" "}
                  </strong>
                </h3>
                <br />
                Indian spices, nuts, vegetables, handicrafts, and coir-based
                products are exported. After venturing into the export and
                import business, the organization has emerged as one of the key
                players in exporting agro-based commodities based on client
                demand. Our company provides services that meet international
                standards. We distribute agricultural products based on "best
                value for money" and are looking to expand our service areas. We
                are one of the most well-known export companies for Indian food
                products all over the world. We serve clients who require import
                and export business in India for spices, herbs, dry fruits,
                natural honey, and handicraft care.
              </p>
            </div>
          </div>
          <div className="col-md-6 text-center">
            <img
              src="https://rukminim1.flixcart.com/image/416/416/jtsz3bk0/vegetable/k/y/d/5-onion-un-branded-no-whole-original-imafdsyg4ykqcyt4.jpeg?q=70"
              alt="about-img"
              height="200px"
            />
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 text-center">
            <img
              src="https://www.jiomart.com/images/product/original/590000097/tender-coconut-1-pc-approx-800-g-1500-g-product-images-o590000097-p590000097-0-202203170923.jpg"
              alt="about-img"
              height="300px"
            />
          </div>
          <div className="col-md-6">
            <div>
              <h2>
                <strong>Why Choose Us</strong>
              </h2>
              <p className="mt-5">
                The world of international trade may be challenging and
                complicated, and we at GEO Global Trade are aware of this. To
                help you through the complexities of exporting and importing
                items across borders, we work hard to become your valued
                partner.Only a few justifications for picking us for your import
                and export requirements are listed below:
              </p>
              <li>Expertise</li>
              <li>Worldwide network</li>
              <li>Consumer assistance</li>
              <li>Affordable prices</li>
              <li>Flexibility</li>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        {/* <img
          height={"70px"}
          src="https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/home%20page%2Fred-chilli-pepper-icon-flat-isolated-vector.jpg?alt=media&token=9897c45f-7e8b-47fe-9319-09122ca7d632"
        />
        <img
          height={"70px"}
          src="https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/home%20page%2F3441058.png?alt=media&token=ed4f1a0c-3b32-4d52-b21a-9102d43ee0ea"
        />
        <img
          height={"70px"}
          src="https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/home%20page%2Fcoconut-auglis-icon-png-favpng-E5SKdBpahKpLMfDAQ2Usq0mcs.jpg?alt=media&token=8e1f1ff8-d015-4710-a5e8-75059b937c45"
        /> */}
        <Card
          className="mt-3"
          style={{
            borderRadius: "145px",
            borderBottomLeftRadius: "46px",
            height: "300px",
            backgroundColor: "#38bd38",
            borderTopRightRadius: "48px",
          }}
        >
          <Card.Body>
            {/* <div className="d-flex" style={{ marginLeft: "30%" }}>
              <img
                src="https://cdn-icons-png.flaticon.com/512/1753/1753666.png"
                height="80px"
                style={{ marginTop: "" }}
                className="text-center"
              />

              <img
                src="https://www.inventicons.com/uploads/iconset/705/wm/512/Packing-17.png"
                height="80px"
                style={{ marginTop: "" }}
                className="text-center"
              />
              <img
                src="https://cdn-icons-png.flaticon.com/512/870/870122.png"
                height="80px"
                style={{ marginTop: "" }}
                className="text-center"
              />
            </div> */}
            <Card.Text
              className="text-center"
              style={{ marginTop: "7%", color: "white" }}
            >
              <h6 className="text-center">
                "We are interested in the stages from planting to harvesting,
                cooling, storage, and packing conditions, as well as shipping
                under a controlled environment with conventional or refrigerated
                containers. Our farmer partners, who collaborate closely and
                honestly with our team, receive the findings of our study in
                return, and they are always able to monitor the conditions of
                their deliveries."
              </h6>
              <Button href="/contact-us" className="mt-3" variant="secondary">
                Contact us
              </Button>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="container" style={{ marginTop: "10%" }}>
        <h4 className="text-center">
          <strong>PRODUCT GALLERY</strong>
        </h4>
        <div class="row mt-4">
          <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/Products%2Fpotato-1-kg-product-images-o590000090-p590000090-0-202207291750.jpg?alt=media&token=45329ca6-7e87-4ec3-9e75-619977476dcb"
              class="w-100 shadow-1-strong rounded mb-4"
              alt="Boat on Calm Water"
            />

            <img
              class="w-100 shadow-1-strong rounded mb-4"
              alt="Boat on Calm Water"
              src="https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/home%20page%2Fgallery%2FCurry-Leaf-Plant-Care.jpg?alt=media&token=1880275d-e036-4ce0-abad-9829e40f1ab8"
              class="w-100 shadow-1-strong rounded mb-4"
              alt="Wintry Mountain Landscape"
            />
          </div>

          <div class="col-lg-4 mb-4 mb-lg-0">
            <img
              src="https://e1.pxfuel.com/desktop-wallpaper/428/278/desktop-wallpaper-vegetables-bitter-gourds-bitter-gourd.jpg"
              class="w-100 shadow-1-strong rounded mb-4"
              alt="Mountains in the Clouds"
            />

            <img
              src="https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/home%20page%2Fmint-leaves-1.jpg?alt=media&token=7b3d929c-c32f-4f7b-a392-26d30f6495bd"
              class="w-100 shadow-1-strong rounded mb-4"
              alt="Boat on Calm Water"
            />
          </div>

          <div class="col-lg-4 mb-4 mb-lg-0">
            <img
              src="https://dl.helpkade.com/wp-content/uploads/20200806/128-1288741_individual-pictures-of-fruit-and-vegetables-individual-fruits.png"
              class="w-100 shadow-1-strong rounded mb-4"
              alt="Waves at Sea"
            />

            <img
              src="https://www.fluentu.com/blog/english/wp-content/uploads/sites/4/2020/02/vegetables-name-in-english-6.jpg"
              class="w-100 shadow-1-strong rounded mb-4"
              alt="Yosemite National Park"
            />
          </div>
        </div>
      </div>

      <div style={{ marginTop: "10%" }}>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
