// OtherPage.js
import React from "react";

function OtherPage() {
  return (
    <div>
      {/* <h2>Other Page</h2>
       */}
      <a
        class="paynowtext"
        href="upi://pay?pa=sivakarthik2805-1@okhdfcbank&pn=NATARAJAN G&cu=INR"
      >
        Buy now
      </a>
      {/* Your other page content */}
    </div>
  );
}

export default OtherPage;
