// import section start
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./css/footer.css";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
// import bottomwhatsapp from "../images/bottomwhatsapp.svg";
// import bottommail from "../images/bottommail.svg";
// import bottomcall from "../images/bottomcall.svg";
// import mailfooter from "../images/mailfooter.svg";
// import phonecallfooter from "../images/phonecallfooter.svg";
// import {
//   FacebookShareButton,
//   TwitterShareButton,
//   LinkedinShareButton,
// } from "react-share";
// import { FacebookIcon, TwitterIcon, LinkedinIcon } from "react-share";
// import section End

// Main function start
const Footer = () => {
  const navigate = useNavigate();
  const [href, setHref] = useState("");
  const [currentYear, setCurrentYear] = useState("");

  useEffect(() => {
    setHref(window.location.href);
    const d = new Date();
    let year = d.getFullYear();
    setCurrentYear(year);
  }, []);

  function navigation_app(value) {
    var trim_data_lower_case = value.toLowerCase();
    navigate(`/city/${trim_data_lower_case}`);
    // window.location.reload();
  }

  function static_page_click_footer(pathname) {
    navigate("/" + pathname);
    window.location.reload();
  }
  function categoryClick(paths) {
    navigate("/" + paths);
    window.location.reload();
  }
  function classcomponent() {
    navigate("/housetype/villa-chennai");
    //  window.location.reload();
  }
  return (
    <footer className="mainfooter" role="contentinfo">
      <div className="container">
        <div className="row pt-60 pb-60">
          <div className="col-md-3 responsive-mb-15 mt-4">
            <h5 className="text-white">About us</h5>
            <div className="mt-4">
              <p className="text-white-light m-0">
                Exporters in Tamilnadu, GEO Global Trade, believes that
                long-term success is powered by trust, client happiness,
                quality, ethical business methods, and the service we offer to
                our customers
              </p>
            </div>
            <img
              className="mt-4"
              height={"40px"}
              src="https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/Footer%2FFacebook-Icon-PNG-36.png?alt=media&token=f4a0bddf-7868-43f4-bbb3-c309e1a9b531"
            />
            <img
              className="mt-4"
              height={"40px"}
              style={{ paddingLeft: "20px" }}
              src="https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/Footer%2F580b57fcd9996e24bc43c521.png?alt=media&token=9c1920c6-68d2-4091-8a1b-cbd7ba29c4e1"
            />
            <img
              className="mt-4"
              height={"40px"}
              style={{ paddingLeft: "20px" }}
              src="https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/Footer%2Fget-logo-whatsapp-png-pictures-1.png?alt=media&token=454d76bf-6941-4c54-a3db-e307cb402d33"
            />
            {/* <FacebookShareButton size={32} round={true} />
            <InstapaperShareButton />
            <LinkedinShareButton /> */}
          </div>
          <div className="col-md-3 responsive-mb-15 mt-4"></div>
          <div className="col-md-3 responsive-mb-15 mt-4">
            <h5 className="text-white">Quick menu</h5>
            <div className="mt-4">
              <p className="text-white-light m-0">
                <a href="/blog" className="text-white-light m-0 pointer">
                  Blog
                </a>
              </p>
              <p className="text-white-light m-0 mt-3">
                <a href="/contact-us" className="text-white-light m-0 pointer">
                  Contact us
                </a>
              </p>
              <p className="text-white-light m-0">
                {/* <a
                  onClick={() => navigation_app("Coimbatore")}
                  className="text-white-light m-0 pointer"
                >
                  Coimbatore
                </a> */}
              </p>
            </div>
          </div>
          <div className="col-md-3 mt-4">
            <h5 className="text-white">Contact us</h5>
            <div className="mt-4 d-flex">
              <div className="ptr-3-10">
                {/* <img
                  alt="mail-footer"
                  src={mailfooter}
                  width="18"
                  height="18"
                /> */}
              </div>
              <p className="text-white-light m-0">geo@gmail.com</p>
            </div>
            <div className="mt-4 d-flex">
              <div className="ptr-3-10">
                {/* <img
                  alt="phone-call"
                  src={phonecallfooter}
                  width="18"
                  height="18"
                /> */}
              </div>
              <div>
                <a href="tel:099406 14444" target="_blank">
                  <p className="text-white-light m-0">123456789</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr></hr>
          </div>
        </div>
        <div className="row" style={{ borderTop: "1px solid silver" }}>
          <div className="col-md-12">
            <p className="text-white-light text-center">
              Copyright GEO, All rights reserved {currentYear}
            </p>
          </div>
        </div>
      </div>
      <div className="bottom-social-icon d-flex">
        <a href="tel:099406 14444" target="_blank">
          {/* <img alt="phone-icon" src={bottomcall} width="45" height="45" /> */}
        </a>
        <a href="mailto:cgchomesllp@gmail.com" target="_blank">
          {/* <img alt="mail-icon" src={bottommail} width="45" height="45" /> */}
        </a>
        <a href="https://api.whatsapp.com/send?phone=919677051111&text=Hi%21+I%27m+interested+in+your+service%21+Please+share+more+details.+Thanks%21">
          {/* <img alt="watsapp-icon" src={bottomwhatsapp} width="45" height="45" /> */}
        </a>
        {/* <FacebookShareButton
        url={href}
      >
        <FacebookIcon width='45' height='45' style={{marginLeft:"5px"}} round /> 
      </FacebookShareButton>
      <TwitterShareButton
        url={href}
      >
        <TwitterIcon style={{marginLeft:"5px"}}  width='45' height='45' round /> 
      </TwitterShareButton>
      <LinkedinShareButton
        url={href}
      >
        <LinkedinIcon style={{marginLeft:"5px"}} width='45' height='45' round /> 
      </LinkedinShareButton> */}
      </div>
    </footer>
  );
};
// Main function end
export default Footer;
