import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "./footer";
import { Button } from "react-bootstrap";
import CarouselPart from "../carousuel/carosuel";

function Contact() {
  return (
    <div>
      <Navbar />
      {/* <CarouselPart /> */}
      <div className="container top-important">
        <div className="row">
          <div className="col-md-6">
            <h2>
              <strong>Contact Us</strong>
            </h2>
            <form>
              <div class="form-outline mb-4">
                <label class="form-label" for="form4Example1">
                  Name
                </label>
                <input type="text" id="form4Example1" class="form-control" />
              </div>
              <div class="form-outline mb-4">
                <label class="form-label" for="form4Example2">
                  Email address
                </label>
                <input type="email" id="form4Example2" class="form-control" />
              </div>
              <div class="form-outline mb-4">
                <label class="form-label" for="form4Example3">
                  Message
                </label>
                <textarea
                  class="form-control"
                  id="form4Example3"
                  rows="4"
                ></textarea>
              </div>

              <Button type="submit" className="enq-btn mb-4">
                Submit
              </Button>
            </form>
          </div>
          <div className="col-md-6">
            {/* <img
              src="
            https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/contact-us%2F485563.webp?alt=media&token=b0893160-f6df-4172-b081-ea9e88906fec"
              alt="contact-img"
              height="400px"
              width={"400px"}
            /> */}
            <div>
              <iframe
                width="600"
                height="450"
                frameborder="0"
                style={{ border: "0" }}
                src="https://www.openstreetmap.org/export/embed.html?bbox=80.1560,12.8376,80.3018,13.0039&layer=mapnik&marker=13.0337,80.2350"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "10%" }}>
        <Footer />
      </div>
    </div>
  );
}

export default Contact;
