import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./navbar.scss";
import db from "../firebaseConfig/firebaseConfig";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

function Click() {
  var navbar = document.querySelector(".main-nav ul");
  navbar.classList.toggle("active");
}

function Navbar() {
  const [currentPath, setCurrentPath] = useState("");
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [num, setNum] = useState("");
  const [toastSuccess, setToastSuccess] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSuccess = () => {
    // Add a new document in collection "cities"
    if (name === "" || email === "" || num === "") {
      document.getElementById("name-id").style.display = "none";
      document.getElementById("email-id").style.display = "none";
      document.getElementById("num-id").style.display = "none";
      if (name === "" && email === "" && num === "") {
        document.getElementById("name-id").style.display = "block";
        document.getElementById("email-id").style.display = "block";
        document.getElementById("num-id").style.display = "block";
      } else if (name === "") {
        document.getElementById("name-id").style.display = "block";
      } else if (email === "") {
        document.getElementById("email-id").style.display = "block";
      } else if (num === "") {
        document.getElementById("num-id").style.display = "block";
      } else {
        document.getElementById("name-id").style.display = "block";
        document.getElementById("email-id").style.display = "block";
        document.getElementById("num-id").style.display = "block";
      }
    } else {
      db.collection("enquiry_collection")
        .doc()
        .set({
          enquiry_name: name,
          message: message,
          enquiry_mail: email,
          mob_no: num,
        })
        .then(() => {
          setName("");
          setEmail("");
          setMessage("");
          setNum("");
          setToastSuccess(true);
          console.log("Document successfully written!");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    }
  };

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, []);

  return (
    <header className="main-header fixed-top">
      <a href="index.html" className="brand-logo">
        <div className="brand-logo-name">
          {/* <h1 style={{ color: "black" }}>GEO</h1> */}
          <img
            style={{ height: "70px" }}
            src="https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/logo%2FGEO-PNG.png?alt=media&token=30e16ce8-2f27-4802-991b-f52439132931"
            alt="geo-logo"
          />
        </div>
      </a>
      <div href="#" className="toggle-button" onClick={Click}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <nav className="main-nav">
        <ul>
          <li>
            <a className={currentPath === "/" ? "activeNav" : ""} href="/">
              Home
            </a>
          </li>
          <li>
            <a
              className={currentPath === "/about-us" ? "activeNav" : ""}
              href="/about-us"
            >
              About Us
            </a>
          </li>
          <li>
            <a
              className={currentPath === "/blog" ? "activeNav" : ""}
              href="/blog"
            >
              Blog
            </a>
          </li>
          <li>
            <a href="/our-products">Our Products</a>
            {/* <ul className="nav-dropdown">
              <li>
                <a
                // onClick={() => apartment_click(nav_city, "apartment")}
                // id={nav_city}
                >
                  vegetables
                </a>
              </li>
            </ul> */}
          </li>

          <li>
            <a
              className={currentPath === "/contact-us" ? "activeNav" : ""}
              href="/contact-us"
            >
              Contact Us
            </a>
          </li>
          <li style={{ paddingLeft: "20px" }}>
            <Button className="enq-btn" onClick={() => setShow(true)}>
              Enquiry
            </Button>
          </li>
        </ul>
      </nav>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>GEO ENQUIRY</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter a name"
                autoFocus
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <p
                className=""
                id="name-id"
                style={{ display: "none", color: "red" }}
              >
                Enter a name
              </p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p
                className=""
                id="email-id"
                style={{ display: "none", color: "red" }}
              >
                Enter a email
              </p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Mobile number</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter a mobile number"
                value={num}
                onChange={(e) => setNum(e.target.value)}
              />
              <p
                className=""
                id="num-id"
                style={{ display: "none", color: "red" }}
              >
                Enter a mobile number
              </p>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#69AB1A" }}
            onClick={handleSuccess}
          >
            Save Changes
          </Button>
        </Modal.Footer>
        <ToastContainer className="p-3" position={"bottom-end"}>
          <Toast show={toastSuccess}>
            <Toast.Header
              onClick={() => setToastSuccess(false)}
              style={{ color: "white", backgroundColor: "#69AB1A" }}
              closeButton={true}
            >
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">GEO</strong>
              {/* <small>11 mins ago</small> */}
            </Toast.Header>
            <Toast.Body>
              Enquiry detail was successfully submited. We will shortly reach
              out
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </Modal>
    </header>
  );
}

export default Navbar;
