import firebase from "firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyB17oHkqrnjejNyf5tNRdJ3YpuEoYG02-k",
  authDomain: "surfgeo-sale.firebaseapp.com",
  projectId: "surfgeo-sale",
  storageBucket: "surfgeo-sale.appspot.com",
  messagingSenderId: "541582708626",
  appId: "1:541582708626:web:08991b7959a4333b53fb4b",
  measurementId: "G-HQ303K8HJQ",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();

export default db;
export const auth = firebase.auth();
