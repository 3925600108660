import React, { useState } from "react";
import Navbar from "../navbar/Navbar";
import Carousel from "react-bootstrap/Carousel";
import Footer from "./footer";
import CarouselPart from "../carousuel/carosuel";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import db from "../firebaseConfig/firebaseConfig";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Form from "react-bootstrap/Form";

function OurProducts() {
  const [productArray, setProductArray] = useState([
    {
      name: "Potato",
      descrip:
        "A root vegetable, the potato is the most adaptable of all. It is frequently combined with several other vegetables in Indian cuisine to produce a variety of delectables. Potatoes go with all delectable cuisines, whether they include brinjal, cauliflower, tomato, onion, soy bean chunks, or members of the spinach family. Purchase KGs of potatoes at this moment online.",
      img: "https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/Products%2Fpotato-1-kg-product-images-o590000090-p590000090-0-202207291750.jpg?alt=media&token=45329ca6-7e87-4ec3-9e75-619977476dcb",
    },
    {
      name: "Coconuts",
      descrip:
        "Coconut is quite potent and comes from tropical areas. It is consumed all throughout the world in a variety of forms, including coconut milk, water, and oil. Inside the coconut shell is a raw white meat known as the kernel. The edible, sweet-tasting, firm-textured, and delectable Coconut Kernel is the coconut's main edible component. The grated raw meat is cold pressed to create coconut milk and cream. For baking or cooking, dried kernels can be shredded and preserved. It can also be turned into flour, which is used to make a variety of Indian delicacies. Online shopping for Big Coconut 1 pc .",
      img: "https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/Products%2F3711.webp?alt=media&token=d72e5ec5-bf90-4299-be7d-ff46c993f95a",
    },
  ]);
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [num, setNum] = useState("");
  const [productName, setProductName] = useState("");
  const [toastSuccess, setToastSuccess] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSuccess = () => {
    // Add a new document in collection "cities"
    if (name === "" || email === "" || num === "") {
      document.getElementById("name-id").style.display = "none";
      document.getElementById("email-id").style.display = "none";
      document.getElementById("num-id").style.display = "none";
      if (name === "" && email === "" && num === "") {
        document.getElementById("name-id").style.display = "block";
        document.getElementById("email-id").style.display = "block";
        document.getElementById("num-id").style.display = "block";
      } else if (name === "") {
        document.getElementById("name-id").style.display = "block";
      } else if (email === "") {
        document.getElementById("email-id").style.display = "block";
      } else if (num === "") {
        document.getElementById("num-id").style.display = "block";
      } else {
        document.getElementById("name-id").style.display = "block";
        document.getElementById("email-id").style.display = "block";
        document.getElementById("num-id").style.display = "block";
      }
    } else {
      db.collection("enquiry_collection")
        .doc()
        .set({
          enquiry_name: name,
          message: message,
          enquiry_mail: email,
          mob_no: num,
          enquiry_product: productName,
        })
        .then(() => {
          setName("");
          setEmail("");
          setMessage("");
          setNum("");
          setToastSuccess(true);
          console.log("Document successfully written!");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    }
  };
  function enquiryFormOpen(val) {
    setProductName(val);
    setShow(true);
  }
  return (
    <div>
      <Navbar />
      {/* <CarouselPart /> */}

      <div
        className="container top-important-about"
        style={{ marginTop: "10%" }}
      >
        <div className="row">
          <h2>
            <strong>Our Products</strong>
          </h2>
          {/* <div className="col-md-12 mt-5"> */}
          <div></div>
          {productArray.map((val) => (
            <Card className="mt-3">
              <Card.Header as="h5">
                <strong>{val.name}</strong>
              </Card.Header>
              <Card.Body>
                <div className="d-flex">
                  <img
                    height={"100px"}
                    width="100px"
                    alt={val.name}
                    src={val.img}
                  />
                  <Card.Text>{val.descrip}</Card.Text>
                </div>
                <div style={{ marginLeft: "10%" }}>
                  <Button
                    className="enq-btn mt-4"
                    onClick={() => enquiryFormOpen(val.name)}
                  >
                    Enquiry
                  </Button>
                </div>
              </Card.Body>
            </Card>
          ))}
          {/* </div> */}
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{productName} Enquiry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Enter a name"
                  autoFocus
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p
                  className=""
                  id="name-id"
                  style={{ display: "none", color: "red" }}
                >
                  Enter a name
                </p>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p
                  className=""
                  id="email-id"
                  style={{ display: "none", color: "red" }}
                >
                  Enter a email
                </p>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Mobile number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter a mobile number"
                  value={num}
                  onChange={(e) => setNum(e.target.value)}
                />
                <p
                  className=""
                  id="num-id"
                  style={{ display: "none", color: "red" }}
                >
                  Enter a mobile number
                </p>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              style={{ backgroundColor: "#69AB1A" }}
              onClick={handleSuccess}
            >
              Save Changes
            </Button>
          </Modal.Footer>
          <ToastContainer className="p-3" position={"bottom-end"}>
            <Toast show={toastSuccess}>
              <Toast.Header
                onClick={() => setToastSuccess(false)}
                style={{ color: "white", backgroundColor: "#69AB1A" }}
                closeButton={true}
              >
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">GEO</strong>
                {/* <small>11 mins ago</small> */}
              </Toast.Header>
              <Toast.Body>
                Enquiry detail was successfully submited. We will shortly reach
                out
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </Modal>
      </div>
      <div style={{ marginTop: "10%" }}>
        <Footer />
      </div>
    </div>
  );
}

export default OurProducts;
