// all pages connected are Routing in this js file
// import section start
import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Home from "../App";
import Blog from "../pages/blog";
import About from "../pages/about";
import Contact from "../pages/contact";
import OurProducts from "../pages/products";
import OtherPage from "../pages/payment";

// import VillaPage from "./component/classComponent";
// import VillaAbout from "./component/vila";
// import "@fontsource/rubik";
// import firebaseApp from "firebase/app";

// import section end

// main function start
export default function Router() {
  //   useEffect(() => {
  //     // listen();
  //     firebaseApp
  //       .auth()
  //       .signInAnonymously()
  //       .then(() => {})
  //       .catch((error) => {
  //         console.log(error);
  //         firebaseApp
  //           .auth()
  //           .signInAnonymously()
  //           .then(() => {})
  //           .catch((error) => {
  //             if (error.code === "auth/operation-not-allowed") {
  //             }
  //           });
  //         if (error.code === "auth/operation-not-allowed") {
  //         }
  //       });

  //     window.addEventListener("pageshow", (event) => {
  //       const historyTraversal =
  //         event.persisted ||
  //         (typeof window.performance != "undefined" &&
  //           window.performance.navigation.type === 2);

  //       if (historyTraversal) {
  //         window.location.reload();
  //       }
  //     });
  //   }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Navbar />}> */}
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/our-products" element={<OurProducts />} />
        <Route path="/payment" element={<OtherPage />} />
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

// main function end
ReactDOM.render(<Router />, document.getElementById("root"));
