import React from "react";
import Navbar from "../navbar/Navbar";
import Carousel from "react-bootstrap/Carousel";
import Footer from "./footer";
import CarouselPart from "../carousuel/carosuel";

function About() {
  return (
    <div>
      <Navbar />
      {/* <CarouselPart /> */}

      <div
        className="container top-important-about"
        style={{ marginTop: "10%" }}
      >
        <div className="row">
          <h2>
            <strong>About Us</strong>
          </h2>
          <p className="mt-5">
            Exporters in Tamilnadu, GEO Global Trade, believes that long-term
            success is powered by trust, client happiness, quality, ethical
            business methods, and the service we offer to our customers. Our
            team, which has in-depth knowledge of Indian spices and agricultural
            products, has committed to upholding the highest standards of
            quality, quick delivery, and customer relations. We have established
            ourselves as the leading exporter thanks to our aggressive pricing,
            specialized packaging, high international quality standards, rapid
            delivery of goods, stringent and individualized quality checks, and
            qualified professionals. Our organization was founded on principles
            that set us apart and direct our actions. We operate our business in
            a morally and socially responsible way, which benefits the community
            we work in.
          </p>
          {/* <p className="mt-3">
            Our team, which has in-depth knowledge of Indian spices and
            agricultural products, has committed to upholding the highest
            standards of quality, quick delivery, and customer relations.
          </p> */}
          {/* <p className="mt-3">
            We have established ourselves as the leading exporter thanks to our
            aggressive pricing, specialized packaging, high international
            quality standards, rapid delivery of goods, stringent and
            individualized quality checks, and qualified professionals.
          </p>
          <p className="mt-3">
            Our organization was founded on principles that set us apart and
            direct our actions. We operate our business in a morally and
            socially responsible way, which benefits the community we work in.
          </p> */}
          <div
            className="col-md-6 mt-5"
            style={{ borderRight: "1px solid black" }}
          >
            <h4>
              <strong>Our Vision</strong>
            </h4>
            <p className="mt-5">
              GEO Impex's goals are to meet the needs of its clients in India
              and throughout the world for healthy ingredients.
            </p>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-6"></div>
          <div
            className="col-md-6 mt-5"
            style={{ borderLeft: "1px solid black" }}
          >
            <h4>
              <strong>Our Mission</strong>
            </h4>
            <p className="mt-5">
              Our business is managed by a young, professional team committed to
              giving clients items they can trust in terms of quality,
              availability, and cost.
            </p>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "10%" }}>
        <Footer />
      </div>
    </div>
  );
}

export default About;
